

export default {
  name: 'ProducerSearch',
  props: {
    searchTerm: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      loading: false,
      search: null,
      selectbox: null,
      path: 'contacts',
      producerSelected: null,
      items: [],
      activeOrder: {}

    }
  },
  computed: {
    members () {
      const producers = this.$store.getters[`${this.path}/standardized`]
      return this.arrHas(producers, 'producesDp', 1)
    }
  },
  watch: {
    search (newValue, oldValue) {
      if (newValue) {
        this.debouncedQuery()
      }
    },
    searchTerm (newValue, oldValue) {
      if (newValue) {
        this.search = newValue
      }
    }
  },
  created () {
    this.debouncedQuery = _.debounce(this.querySelections, 500)
  },
  mounted () {
    this.$refs.autocomplete.$refs.input.addEventListener('focus', this.onFocus, true)
  },
  methods: {
    onFocus () {
      this.$refs.autocomplete.isMenuActive = true
    },
    querySelections () {
      const contacts = this.members
      const search = this.search

      if (search) {
        this.items = contacts.filter(data => data.full_name.toLowerCase().includes(search.toLowerCase()))
        this.$refs.autocomplete.$refs.input.focus()
      }
    },
    openOrderForm (data) {
      this.$emit('openOrderForm', data)
      this.search = null
      this.selectbox = null
      this.items = []
    },
    openUsageForm (data) {
      this.$emit('openUsageForm', data)
      this.search = null
      this.selectbox = null
      this.items = []
    }
  }
}
