// State ======================
import globalActions from '../api/GlobalActions.js'
import globalMutations from '../api/GlobalMutations.js'
import globalGetters from '../api/GlobalGetters.js'

export const state = () => ({
  type: 'ditches',
  effectedByYear: false,
  yearFields: [],
  relations: [
    {
      name: 'farmunits',
      translate: 'farmunits',
      value: 'farmunits',
      storename: 'farmunits',
      yearFields: []
    }
  ],
  all: {},
  reference: {
    /* for showing paginated resources ... needs a bit more work. */
  },
  active: false,
  page: 1,
  pageCount: 0,
  pagination: {
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    itemsPerPage: 150,
    sort: true,
    sortBy: 'name',
    page: 1,
    sortDesc: []
  },
  print_path: '',
  search: null,
  total: 0,
  print: false,
  export: false,
  bound: {
    type: '',
    date_range: null
  },
  filter: {
    attr_between_date: {
      date_range: {
        start: new Date().getFullYear() + '-01-01',
        end: new Date().getFullYear() + '-12-31'
      }
    }
  },
  // members are called contractors on the server
  include: '',
  headers: {},
  notifications: {}
})

// Mutations ==================

export const mutations = {
  ...globalMutations
}

// Actions ====================

export const actions = {
  ...globalActions
}

// Getters ====================

export const getters = {
  ...globalGetters
}
