
export default {
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    load: false
  }),
  computed: {
    loadCount() {
      return this.$store.state.system.loadCount
    },
    loadStatus() {
      return this.$store.state.system.loadStatus
    },
    percentage() {
      const multiplier = 100 / this.loadCount
      return this.loadStatus * multiplier
    },
    isLoading: {
      get() {
        return this.loading
      },
      set(value) {
        return value
      }
    }
  },
  methods: {
    start() {
      this.isLoading = true
      this.load = true
      console.log('loading start')
    },
    finish() {
      this.isLoading = false
      this.load = false
    }
  }
}
