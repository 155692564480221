export default ($axios, $store) => (resource, subresource) => ({

  index (x) {
    return $axios
      .$get(`api/${resource}`, { params: x })
      .then((results) => {
        return results
      })
      .catch((errors) => {
        console.log({ errors })
      })
  },
  subIndex (id, x) {
    return $axios
      .$get(`api/${resource}/${id}/${subresource}`, { params: x })
      .then((results) => {
        return results
      })
      .catch((errors) => {
        console.log({ errors })
      })
  },
  show (id, x) {
    return $axios
      .$get(`api/${resource}/${id}`, { params: x })
      .then((results) => {
        return results
      })
      .catch((errors) => {
        console.log({ errors })
      })
  },
  create (payload) {
    return $axios
      .$post(`api/${resource}`, payload)
      .then((results) => {
        return results
      })
      .catch((errors) => {
        return errors
      })
  },
  subCreate (parentId, payload) {
    return $axios
      .$post(`api/${resource}/${parentId}/${subresource}`, payload)
      .then((results) => {
        return results
      })
      .catch((errors) => {
        return errors
      })
  },
  update ({ id, payload }) {
    return $axios
      .$put(`api/${resource}/${id}`, payload)
      .then((results) => {
        return results
      })
      .catch((errors) => {
        return errors
      })
  },
  subUpdate (parentId, childId, payload) {
    return $axios
      .$put(`api/${resource}/${parentId}/${subresource}/${childId}`, payload)
      .then((results) => {
        return results
      })
      .catch((errors) => {
        return errors
      })
  },
  delete (id) {
    return $axios
      .$delete(`api/${resource}/${id}`)
      .then((results) => {
        return results
      })
      .catch((errors) => {
        return errors
      })
  },
  subDelete (parentId, childId) {
    return $axios
      .$delete(`api/${resource}/${parentId}/${subresource}/${childId}`)
      .then((results) => {
        return results
      })
      .catch((errors) => {
        return errors
      })
  },
  print (x) {
    return $axios
      .$get(`api/${resource}`, { params: x })
      .then((results) => {
        return results
      })
      .catch((errors) => {
        return errors
      })
  },
  postPrint (payload) {
    return $axios
      .$post('api/postTunnel', payload)
      .then((results) => {
        return results
      })
      .catch((errors) => {
      })
  },
  subPrint (id, x) {
    return $axios
      .$get(`api/${resource}/${id}/${subresource}`, { params: x })
      .then((results) => {
        return results
      })
      .catch((errors) => {
      })
  },
  showPrint (id, x) {
    return $axios
      .$get(`api/${resource}/${id}`, { params: x })
      .then((results) => {
        return results
      })
      .catch((errors) => {
      })
  },
  // export
  export (x) {
    return $axios
      .$get(`api/${resource}`, { params: x })
      .then((results) => {
        return results
      })
      .catch((errors) => {
      })
  },
  subExport (id, x) {
    return $axios
      .$get(`api/${resource}/${id}/${subresource}`, { params: x })
      .then((results) => {
        return results
      })
      .catch((errors) => {
      })
  },
  showExport (id, x) {
    return $axios
      .$get(`api/${resource}/${id}`, { params: x })
      .then((results) => {
        return results
      })
      .catch((errors) => {
      })
  }
})
