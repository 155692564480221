import Vue from 'vue'
import _ from 'lodash'
import VueClipboard from 'vue-clipboard2'
// import VueExcelXlsx from 'vue-excel-xlsx'
import VueHtml2pdf from 'vue-html2pdf'
import moment from 'moment'

if (!Vue.__my_mixin__) {
  Vue.__my__mixin__ = true
  Vue.mixin({
    methods: {
      showErrorSnack (errorMessage) {
        this.$store.commit('snackbar/showMessage', { content: { message: errorMessage, status: 500 }, color: 'error' })
      },
      showSuccessSnack (message) {
        this.$store.commit('snackbar/showMessage', { content: { message, status: 200 }, color: 'success' })
      },
      isEmptyObject (obj) {
        return JSON.stringify(obj) === '{}'
      },
      cloneObj (obj) {
        return JSON.parse(JSON.stringify(obj))
      },
      objHas (obj, field) {
        if (typeof obj === 'object') {
          return Object.prototype.hasOwnProperty.call(obj, field)
        }
        return false
      },
      objToArray (obj) {
        return Object.values(this.cloneObj(obj))
      },
      objHasAndNotEmpty (obj, field) {
        if (this.objHas(obj, field)) {
          if (obj[field]) {
            return true
          }
        }
        return false
      },
      fullTextSearch (array, value) {
        array.filter(data => JSON.stringify(data).replace(/("\w+":)/g, '').toLowerCase().includes(value.toLowerCase()))
      },
      // filters
      like (array, key, value) {
        return array.filter(data => JSON.stringify(data[key]).replace(/("\w+":)/g, '').toLowerCase().includes(value.toLowerCase()))
      },
      truthy (array, key, value) {
        if (value === 'true') {
          return array.filter(data => data[key] === true)
        }
        return array.filter(data => data[key] === false)
      },
      eq (array, key, value) {
        return array.filter((data) => {
          return typeof value === 'string'
            ? String(data[key]).toLowerCase() === String(value).toLowerCase()
            : data[key] === value
        })
      },
      date_eq (array, key, date) {
        return array.filter(data => moment(data[key]).isSame(moment(date)))
      },
      date_between (array, key, dates) {
        const dateArray = dates.split(',')
        return array.filter(data => moment(data[key]).isSameOrAfter(dateArray[0]) && moment(data[key]).isSameOrBefore(dateArray[1]))
      },
      neq (array, key, value) {
        return array.filter((data) => {
          return data[key] !== value
        })
      },
      eq_or (array, key, value1, value2) {
        return array.filter((data) => {
          return data[key] === value1 || data[key] === value2
        })
      },

      eq_string (array, key, value) {
        return array.filter((data) => {
          return String(data[key]).toLowerCase() === String(value).toLowerCase()
        })
      },
      obj_value_eq (array, key1, key2, value) {
        return array.filter((data) => {
          return data[key1] && data[key1][key2] === value
        })
      },
      obj_value_find (array, key1, key2, value) {
        const findArr = value.split(',')
        return array.filter((data) => {
          if (!data[key1] || typeof data[key1] !== 'object') {
            return false
          }

          if (Array.isArray(data[key1][key2])) {
            const ids = data[key1][key2]
            return ids.some(r => findArr.includes(r.toString()))
          }

          return findArr.includes(data[key1][key2].toString())
        })
      },
      ltFloat (array, key, value) {
        return array.filter(data => parseFloat(data[key]) < parseFloat(value))
      },
      lt (array, key, value) {
        return array.filter(data => data[key] < value)
      },
      lt_eq (array, key, value) {
        return array.filter((data) => {
          return data[key] && data[key] <= value
        })
      },
      running (array, key, value) {
        return array.filter((usage) => {
          const usageOn = this.$moment(usage.on_date + ' ' + (usage.on_time !== null ? usage.on_time : '00:00'), 'YYYY-MM-DD HH:mm')
          console.log('usageOn', usageOn)
          const onBeforeNow = usageOn <= this.$moment(value, 'YYYY-MM-DD HH:mm')

          let offAfterNow = true
          if (usage.off_date === '') {
            offAfterNow = true
          } else if (usage.off_date) {
            const usageOff = this.$moment(usage.off_date + ' ' + (usage.off_time !== null ? usage.off_time : '00:00'), 'YYYY-MM-DD HH:mm')
            offAfterNow = usageOff >= this.$moment(value, 'YYYY-MM-DD HH:mm')
          }

          return onBeforeNow && offAfterNow
        })
      },
      closed (array, key, value) {
        return array.filter(usage => this.objHasAndNotEmpty(usage, 'off_date')).filter((usage) => {
          const usageOff = this.$moment(usage.off_date + ' ' + (usage.off_time !== null ? usage.off_time : '00:00'), 'YYYY-MM-DD HH:mm')
          console.log('usageOff', usageOff)
          return usageOff <= this.$moment(value, 'YYYY-MM-DD HH:mm')
        })
      },
      lt_eq_date (array, key, value) {
        return array.filter((data) => {
          return !this.isEmpty(data[key]) && (new Date(data[key]) <= value)
        })
      },
      lt_eq_datetime_obj (array, obj, value) {
        return array.filter((data) => {
          return !this.isEmpty(data[obj.date]) && (new Date(`${data[obj.date]}T${data[obj.time]}`) <= value)
        })
      },
      gt_eq_any_datetime_obj (array, obj, value) {
        if (value === 'true') {
          return array.filter(data => !this.isEmpty(data[obj.date]) && !this.isEmpty(data[obj.time]))
        }

        return array.filter((data) => {
          return this.isEmpty(data[obj.date]) || (new Date(`${data[obj.date]}T${data[obj.time]}`) >= new Date(value))
        })
      },
      gt (array, key, value) {
        return array.filter(data => data[key] > value)
      },
      gt_eq (array, key, value) {
        return array.filter(data => data[key] >= value)
      },
      gtFloat (array, key, value) {
        return array.filter(data => data[key] > parseFloat(value))
      },
      arrHas (array, key, value) {
        if (value === 'none') {
          return array.filter(data => !data[key].length)
        }
        return array.filter(data => data[key].length >= value)
      },
      any (array, key, value) {
        if (value === 'true') {
          return array.filter(data => data[key] !== null)
        }
        return array.filter(data => data[key] === null)
      },
      gt_eq_any (array, key, value) {
        if (value === 'true') {
          return array.filter(data => data[key] !== null)
        }

        return array.filter((data) => {
          return this.isEmpty(data[key]) || (data[key] >= value)
        })
      },
      gt_eq_any_date (array, key, value) {
        if (value === 'true') {
          return array.filter(data => data[key] !== null)
        }

        return array.filter((data) => {
          return this.isEmpty(data[key]) || (new Date(data[key]) >= new Date(value))
        })
      },
      lt_eq_dt (array, dateField, value) {
        const timeField = `${String(dateField).replace('date', 'time')}`
        return array.filter(data => !this.isEmpty(data[dateField]) && this.dateObj(data, dateField, timeField).isBefore(moment(value, 'YYYY-MM-DD-HH-mm')))
      },
      gt_eq_any_dt (array, dateField, value) {
        const timeField = `${String(dateField).replace('date', 'time')}`
        return array.filter(data => this.isEmpty(data[dateField]) || this.dateObj(data, dateField, timeField).isAfter(moment(value, 'YYYY-MM-DD-HH-mm')))
      },
      past_dt (array, key, value) {
        const timeField = `${String(key).replace('date', 'time')}`
        return array.filter((data) => {
          return !this.isEmpty(data[key]) && this.dateObj(data, key, timeField).isBefore(moment(value, 'YYYY-MM-DD-HH-mm'))
        })
      },
      is_gt_eq_any_date (item, key, value) {
        if (value === 'true') {
          return item[key] !== null
        }

        return this.isEmpty(item[key]) || (new Date(item[key]) >= new Date(value))
      },
      isIn (array, key, values) {
        if (typeof values === 'string') {
          values = values.split(',').map(v => v.toString().trim())
        }

        return array.filter(data => values.includes(data[key]))
      },
      intersects (array, key, values) {
        if (typeof values === 'string') {
          values = values.split(',').map(v => v.toString().trim())
        }

        return array.filter(data => _.intersection(values, data[key]).length > 0)
      },
      in (array, key, values) {
        if (typeof values === 'string') {
          values = values.split(',').map(v => v.toString().trim())
        }

        return array.filter(data => values.includes(data[key]))
      },
      find (array, key, value) {
        return array.filter((item) => {
          if (!item[key]) {
            return false
          }
          const relationsToFind = value.split(',')

          if (Array.isArray(item[key])) {
            const ids = item[key]
            return ids.some(r => relationsToFind.includes(r.toString()))
          }

          return relationsToFind.includes(item[key].toString())
        })
      },
      isEmpty (value) {
        // If value is null on undefined:
        if (value == null) { return true }

        // By types:
        if (typeof value === 'boolean') { return !value }
        if (typeof value === 'number') { return value === 0 }
        if (typeof value === 'string') { return value === '0' || value.length === 0 }

        // Empty arrays:
        if (Array.isArray(value)) { return value.length === 0 }

        // Empty objects:
        if (value.toString === Object.prototype.toString) {
          switch (value.toString()) {
            case '[object File]':
            case '[object Map]':
            case '[object Set]': {
              return value.size === 0
            }
            case '[object Object]': {
              if (Object.keys(value).length < 1) { return true }

              for (const key in value) {
                if (Object.prototype.hasOwnProperty.call(value, key)) { return false }
              }

              return true
            }
          }
        }

        // Everything else...
        return false
      },
      onlyIds (object) {
        const obj = _.cloneDeep(object)

        for (const key in obj) {
          let val = obj[key]

          if (Array.isArray(obj[key])) {
            val = obj[key].map(item => typeof item === 'object' ? item.id : item)
          } else if (obj[key] && typeof obj[key] === 'object') {
            val = obj[key].id
          }

          obj[key] = val
        }

        return obj
      },
      hasNotRole (roles, key) {
        if (!key) {
          return true
        }
        return !roles.includes(key)
      },
      orderDate (order) {
        return this.$moment(order.run_date + ' ' + (!this.isEmpty(order.run_time) ? order.run_time : '00:00'), 'YYYY-MM-DD HH:mm')
      },
      dateObj (obj, dateField, timeField) {
        return this.$moment(obj[dateField] + ' ' + (!this.isEmpty(obj[timeField]) ? obj[timeField] : (timeField === 'off_date' ? '23:59' : '00:00')), 'YYYY-MM-DD HH:mm')
      },
      currentlyRunning (usages) {
        return usages.filter((order) => {
          const orderOn = this.dateObj(order, 'on_date', 'on_time')
          const onBeforeNow = orderOn.isSameOrBefore(this.$moment())

          let offAfterNow = true
          if (!this.isEmpty(order.off_date)) {
            const orderOff = this.dateObj(order, 'off_date', 'off_time')
            offAfterNow = orderOff.isSameOrAfter(this.$moment())
          }

          return onBeforeNow && offAfterNow
        })
      },
      activeOnOrders (orders, dateField = 'run_date', timeField = 'run_time') {
        return orders.filter((order) => {
          const orderOn = this.dateObj(order, dateField, timeField)
          return orderOn.isSameOrBefore(this.$moment())
        })
      },
      upcoming (usages, dateField = 'on_date', timeField = 'on_time') {
        return usages.filter((order) => {
          const orderOn = this.dateObj(order, dateField, timeField)
          return orderOn.isSameOrAfter(this.$moment())
        })
      },
      closedUsages (usages) {
        return usages
          .filter(usage => this.objHasAndNotEmpty(usage, 'off_date'))
          .filter((order) => {
            const orderOff = this.dateObj(order, 'off_date', 'off_time')
            return orderOff.isSameOrBefore(this.$moment())
          })
      },
      getTotalPartialBilling (orders, om) {
        return orders.reduce((accm, order) => accm + Number(this.generatePartialBilling(order, om)), 0.00)
      },
      generatePartialBilling (order, om) {
        const discountStart = this.$moment(om.discount_start)
        const discountEnd = this.$moment(om.discount_end)
        const discount = Number(om.discount)
        const hourlyAF = Number(om.current_use_constant) / 24
        const flow = Number(order.flow)
        const orderOn = this.dateObj(order, 'on_date', 'on_time')
        const orderOff = this.$moment()
        let hours = 0
        let af = 0
        let discountAF = 0
        // const discountHours = 0
        if (this.isEmpty(order.on_date) || orderOn.isSameOrAfter(this.$moment()) || order.off_date) {
          return 0.00
        }

        if (orderOn >= discountStart && orderOff <= discountEnd) {
          // Entire order is in discount

          const duration = this.$moment.duration(orderOff.diff(orderOn))
          hours = Number(duration.asHours())

          af = hourlyAF * hours * flow
          af = Number(af) * discount
        } else if (orderOn >= discountEnd || orderOff <= discountStart) {
          // entire order is out of discount
          const duration = this.$moment.duration(orderOff.diff(orderOn))
          hours = Number(duration.asHours())
          af = hourlyAF * hours * flow
        } else if (orderOn >= discountStart && orderOff >= discountEnd) {
          // starts in discount, ends out of discount
          const discountHours = Number(this.$moment.duration(discountEnd.diff(orderOn)).asHours())
          hours = Number(this.$moment.duration(orderOff.diff(discountEnd)).asHours())

          discountAF = discountHours * hourlyAF * flow
          discountAF = Number(discountAF) * discount
          af = hourlyAF * hours * flow
          af = Number(af) + Number(discountAF)
        } else if (orderOn <= discountStart && orderOff <= discountEnd) {
          // starts out of discount, ends in discount
          hours = this.$moment.duration(discountStart.diff(orderOn)).asHours()
          const discountHours = Number(this.$moment.duration(orderOff.diff(discountStart)).asHours())

          discountAF = discountHours * hourlyAF * flow
          discountAF = Number(discountAF) * discount
          af = hourlyAF * hours * flow
          af = Number(af) + Number(discountAF)
        } else if (orderOn <= discountStart && orderOff >= discountEnd) {
          // discounted dates are contained within order on-off date range
          hours = Number(this.$moment.duration(discountStart.diff(orderOn)).asHours())
          const discountHours = Number(this.$moment.duration(discountEnd.diff(discountStart)).asHours())
          hours = hours + Number(this.$moment.duration(orderOff.diff(discountEnd)).asHours())

          discountAF = (discountHours * hourlyAF * flow) * discount
          af = hourlyAF * hours * flow
          af = Number(af) + Number(discountAF)
        } else {
          // catch all - no discount
          // entire order is out of discount
          const duration = this.$moment.duration(orderOff.diff(orderOn))
          hours = Number(duration.asHours())

          af = hourlyAF * hours * flow
        }
        return af
      },
      filterRunningOrders (orders) {
        return orders.filter((order) => {
          // Ensure order is neither null nor undefined
          if (order == null) {
            return false // Skip this order if it is null or undefined
          }

          // Check if both 'on_date' and 'on_time' properties exist
          if (!Object.prototype.hasOwnProperty.call(order, 'on_date') || !Object.prototype.hasOwnProperty.call(order, 'on_time')) {
            return false // Skip this order if either property is missing
          }

          const orderOn = this.dateObj(order, 'on_date', 'on_time')
          const onBeforeNow = orderOn.isSameOrBefore(this.$moment())

          let offAfterNow = true // Assume true unless proven otherwise
          if (!this.isEmpty(order.off_date) && Object.prototype.hasOwnProperty.call(order, 'off_date')) {
            // Check if 'off_time' property exists before creating the date object
            if (!Object.prototype.hasOwnProperty.call(order, 'off_time')) {
              return false // If 'off_date' is provided but 'off_time' is missing, skip this order
            }

            const orderOff = this.dateObj(order, 'off_date', 'off_time')
            offAfterNow = orderOff.isSameOrAfter(this.$moment())
          }

          return onBeforeNow && offAfterNow
        })
      },

      getOrdersInOmYear (orders, om) {
        return orders.filter((order) => {
          const orderOn = this.dateObj(order, 'on_date', 'on_time')
          const orderStartsInYear = orderOn.isSameOrAfter(this.$moment(om.water_year_start + ' 00:00:00', 'YYYY-MM-DD'))

          let orderEndsInYear = true
          if (!this.isEmpty(order.off_date)) {
            const orderOff = this.dateObj(order, 'off_date', 'off_time')
            orderEndsInYear = orderOff.isSameOrBefore(this.$moment(om.water_year_end + ' 23:59:59', 'YYYY-MM-DD'))
          }

          return orderStartsInYear && orderEndsInYear
        })
      },
      getFullyBilled (orders) {
        return orders.filter(o => o.off_date && o.off_time)
          .reduce((acc, order) => acc + Number(order.acre_ft), 0.00)
      },
      getTotalAcresProduced (producer) {
        return producer.producesFu.reduce((acc, fu) => acc + Number(fu.irrigated_acres), 0.00)
      },
      getTotalAcresOwned (owner) {
        return owner.ownsFu.reduce((acc, fu) => acc + Number(fu.irrigated_acres), 0.00)
      }
    }
  })
}

VueClipboard.config.autoSetContainer = true
// Vue.use(VueExcelXlsx)
Vue.use(VueHtml2pdf)
Vue.use(_)
Vue.use(VueClipboard)
