import Vue from 'vue'
import { format, fromUnixTime } from 'date-fns'
import moment from 'moment'

export const formatPhone = (v) => {
  if (!v) {
    return ''
  }
  const s2 = ('' + v).replace(/\D/g, '')
  const m = s2.match(/^(\d{3})(\d{3})(\d{4})$/)
  return !m ? null : '(' + m[1] + ') ' + m[2] + '-' + m[3]
}

export const formatUnixDate = (v) => {
  if (!v) {
    return '--'
  }
  const date = fromUnixTime(v)
  return format(date, 'MMM DD, YYYY')
}

export const formatDateNoYear = (value) => {
  if (!value) {
    return '--'
  }
  const date = new Date(value)
  return format(date, 'MMM DD')
}

export const formatDate = (value) => {
  if (!value) {
    return '--'
  }
  const date = new Date(value)
  return moment.utc(date).format('MMM DD, YYYY')
}

export const formatDateTime = (value) => {
  if (!value) {
    return '--'
  }
  const date = new Date(value)
  return moment.utc(date).format('MMM DD, YYYY hh:mm A')
}

export const formatLocalDate = (value) => {
  if (!value) {
    return '--'
  }
  const date = new Date(value)
  return moment.utc(date).local().format('MMM DD, YYYY')
}

export const formatLocalDateTime = (value) => {
  if (!value) {
    return '--'
  }
  const date = new Date(value)
  return moment.utc(date).local().format('MMM DD, YYYY hh:mm A')
}

export const formatHours = value => value.toFixed(2)

export const formatToFixed = value => Number(value)
  .toFixed(2)
  .toString()
  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')

export const formatMoney = (value) => {
  if (!value) {
    return '--'
  } else {
    return (
      '$' + (value / 1).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    )
  }
}

export const pluralize = (n, w) => {
  if (n === 1) {
    return w
  } else {
    return w + 's'
  }
}

export const formatTime = (value) => {
  const date = new Date(value)
  return format(date, 'h:mm A')
}

export const formatNotification = value => value

export const titleCase = (value) => {
  let str = value.toLowerCase()
  str = str.split(' ')

  for (let i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1)
  }
  return str.join(' ')
}

export const timeAgo = (value) => {
  function pluralize (n, w) {
    if (n === 1) {
      return n + ' ' + w
    } else {
      return n + ' ' + w + 's'
    }
  }
  const timeDiff = new Date() - new Date(value)
  const between = timeDiff / 1000
  if (between < 3600) {
    return pluralize(~~(between / 60), ' minute')
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), ' hour')
  } else {
    return pluralize(~~(between / 86400), ' day')
  }
}

Vue.filter('formatPhone', formatPhone)
Vue.filter('formatUnixDate', formatUnixDate)
Vue.filter('formatDateNoYear', formatDateNoYear)
Vue.filter('formatDate', formatDate)
Vue.filter('formatDateTime', formatDateTime)
Vue.filter('formatLocalDate', formatLocalDate)
Vue.filter('formatLocalDateTime', formatLocalDateTime)
Vue.filter('formatTime', formatTime)
Vue.filter('formatHours', formatHours)
Vue.filter('formatToFixed', formatToFixed)
Vue.filter('formatMoney', formatMoney)
Vue.filter('pluralize', pluralize)
Vue.filter('formatNotification', formatNotification)
Vue.filter('titleCase', titleCase)
Vue.filter('timeAgo', timeAgo)
