
import ProducerSearch from '@/components/baseForm/ProducerSearch.vue'

export default {
  data () {
    return {
      initialLoadCompleted: false,
      isClient: false,
      clipped: true,
      drawer: true,
      syncing: false,
      openOrderCard: false,
      openUsageCard: false,
      usageData: {},
      orderData: {},
      searchOptions: [
        {
          text: 'Producer Search',
          value: 'BaseFormProducerSearch'
        },
        {
          text: 'Owner Search',
          value: 'BaseFormOwnerSearch'
        },
        {
          text: 'Farm Unit Search',
          value: 'BaseFormFarmunitSearch'
        }
      ],
      afterPrecache: false,
      searchValue: 'BaseFormProducerSearch',
      menuItems: [
        {
          icon: 'mdi-database-sync',
          key: 'dock',
          title: 'Dock',
          to: '/dock'
        },
        {
          icon: 'mdi-monitor-dashboard',
          key: 'home',
          title: 'Dashboard',
          to: '/'
        },
        {
          icon: 'mdi-water-pump',
          key: 'orders',
          title: 'Orders',
          to: '/pg/orders'
        },
        {
          icon: 'mdi-account-box',
          key: 'contacts',
          title: 'Contacts',
          to: '/pg/contacts'
        },
        {
          icon: 'mdi-waves',
          key: 'waterMasterReport',
          title: 'Water Master Report',
          to: '/waterMasterReport'
        },
        {
          icon: 'mdi-home-silo-outline',
          key: 'farmunits',
          title: 'Farm Units',
          to: '/pg/farmunits'
        },
        {
          icon: 'mdi-chart-bar',
          key: 'reports',
          title: 'Reports',
          to: '/reports'
        }

      ],
      settings: [
        {
          icon: 'mdi-map',
          key: 'divisions',
          title: 'Divisions',
          to: '/pg/divisions'
        },
        {
          icon: 'mdi-waves',
          key: 'ditches',
          title: 'Ditches',
          to: '/pg/ditches'
        },
        {
          icon: 'mdi-cog',
          key: 'omsettings',
          title: 'O&M Settings',
          to: '/pg/omsettings'
        },
        {
          icon: 'mdi-flag',
          key: 'flags',
          title: 'Flags',
          to: '/pg/flags'
        },
        {
          icon: 'mdi-wifi-off',
          key: 'offlineRequests',
          title: 'Offline Requests',
          to: '/pg/offlineRequests'
        }
      ],
      adminsettings: [
        {
          icon: 'mdi-map',
          key: 'divisions',
          title: 'Divisions',
          to: '/pg/divisions'
        },
        {
          icon: 'mdi-waves',
          key: 'ditches',
          title: 'Ditches',
          to: '/pg/ditches'
        },
        {
          icon: 'settings',
          key: 'omsettings',
          title: 'O&M Settings',
          to: '/pg/omsettings'
        },
        {
          icon: 'flags',
          key: 'flags',
          title: 'Flags',
          to: '/pg/flags'
        },
        {
          icon: 'mdi-wifi-off',
          key: 'offlineRequests',
          title: 'Offline Requests',
          to: '/pg/offlineRequests'
        }
      ],
      notifications: [],
      miniVariant: true,
      localForageOverrides: {
        system: ['waterYear', 'availableWaterYears', 'offlinePushRequests'],
        omsettings: ['omOfYear']
      }
    }
  },
  computed: {
    ProducerSearch () {
      return ProducerSearch
    },
    loadCount: {
      get () {
        return this.$store.state.system.loadCount
      },
      set (value) {
        this.$store.commit('system/SET_LOADCOUNT', value)
      }
    },
    loadStatus: {
      get () {
        return this.$store.state.system.loadStatus
      },
      set (value) {
        this.$store.commit('system/SET_LOADSTATUS', value)
      }
    },
    ordersPage () {
      return this.$route.name === 'pg-orders' || this.$route.name === 'pg-orders-id'
    },
    offlineRequestsPage () {
      return !this.isEmpty(this.$route.params) && this.$route.params.slug === 'offlineRequests'
    },
    storeState () {
      return this.$store.state
    },
    loadStores () {
      return this.$store.state.system.loadStores
    },
    waterYear () {
      return this.$store.getters['system/getWaterYear']
    },
    waterYears () {
      return this.$store.getters['system/getAllWaterYears']
    },
    latestWaterYear () {
      return this.$store.getters['system/getLatestWaterYear']
    },
    roles () {
      return this.$store.getters['system/roles']
    },
    is_readonly () {
      return this.$store.getters['system/isReadonly']
    },
    is_admin () {
      return this.roles.includes('admin') ||
                this.roles.includes('superAdmin') ||
                this.roles.includes('super admin')
    }
  },
  watch: {
    storeState: {
      async handler () {
        await this.saveStatetoLocalForage()
      },
      deep: true
    },
    drawer (n, o) {
      if (n === false) {
        this.miniVariant = true
      }
    },
    '$vuetify.breakpoint.mobile': {
      immediate: true,
      handler (n, o) {
        if (n) {
          this.miniVariant = false
        }
      }
    }
  },
  beforeMount () {
    this.isClient = false
  },
  async mounted () {
    if (process.env.NODE_ENV === 'production') {
      navigator.serviceWorker.onmessage = (e) => {
        if (e.data === 'afterPrecache') {
          this.afterPrecache = true
        }
      }
    } else {
      this.afterPrecache = true
    }
    this.loadCount = Object.values(this.storeState).length + 5
    this.loadStatus = 0
    this.$nextTick(() => {
      this.$nuxt.$loading.start()
    })
    await this.getLocalForageToState()

    await this.$store.dispatch('syncStats/show', 1)
    await this.$store.dispatch('syncHistory/list')

    this.loadStatus++
    await this.checkOMSettings(this.$store.state.system.waterYear)
    this.loadStatus++
    this.splitWaitNoWait()
    this.loadStatus++
    await this.fetchLatestDataWait()
    this.loadStatus++
    this.fetchLatestDataNoWait()
    this.loadStatus++
    this.$nuxt.$loading.finish()
    this.loadCount = 0
    this.loadStatus = 0
    this.isClient = true
  },
  methods: {
    openOrderForm (data) {
      console.log('OPEN ORDER layout', { data })
      this.orderData = data
      this.openOrderCard = true
    },
    openUsageForm (data) {
      this.usageData = data
      this.openUsageCard = true
    },
    closeOrderForm () {
      this.orderData = {}
      this.openOrderCard = false
    },
    closeUsageForm () {
      this.usageData = {}
      this.openUsageCard = false
    },
    toggleMenu () {
      if (this.$vuetify.breakpoint.mobile) {
        this.miniVariant = false
        this.drawer = !this.drawer
      } else {
        this.miniVariant = !this.miniVariant
      }
    },
    async getLocalForageToState () {
      for (const [key, value] of Object.entries(this.storeState)) {
        if (this.objHas(this.localForageOverrides, key)) {
          for (const overrideKey of this.localForageOverrides[key]) {
            const overrideValue = await this.$localForage.getItem(
              key + '-' + overrideKey
            )
            if (overrideValue) {
              const override = { key: overrideKey, value: overrideValue }
              await this.$store.commit(`${key}/SET_DYNAMIC`, override)
              this.loadStatus++
            }
          }
        }
        if (this.objHas(value, 'all')) {
          const all = await this.$localForage.getItem(key)
          if (all) {
            await this.$store.commit(`${key}/REPLACE_ALL`, all)
            this.loadStatus++
          }
        }
      }
    },
    async saveStatetoLocalForage () {
      for (const [key, value] of Object.entries(this.storeState)) {
        if (this.objHas(this.localForageOverrides, key)) {
          for (const overrideKey of this.localForageOverrides[key]) {
            await this.$localForage.setItem(
              key + '-' + overrideKey,
              value[overrideKey]
            )
          }
        }
        if (this.objHas(value, 'all')) {
          await this.$localForage.setItem(key, value.all)
        }
      }
    },
    splitWaitNoWait () {
      this.noWaitStores = []
      this.waitStores = []
      for (const store of this.loadStores) {
        const lastUpdated =
                  this.$store.getters[`${this.loadStores[store]}/lastUpdated`]
        if (lastUpdated) {
          this.noWaitStores.push(store)
        } else {
          this.waitStores.push(store)
        }
      }
    },
    async fetchLatestDataWait () {
      for (const store of this.waitStores) {
        await this.$store.dispatch(`${store}/list`)
      }
    },
    fetchLatestDataNoWait () {
      for (const store of this.noWaitStores) {
        this.$store.dispatch(`${store}/list`)
      }
    },
    async fetchData () {
      for (const key of this.loadStores) {
        await this.$store.dispatch(`${key}/list`)
      }
    },
    async saveStateToLocalForage () {
      for (const [key, value] of Object.entries(this.storeState)) {
        if (this.objHas(value, 'all')) {
          await this.$localForage.setItem(key, value.all)
        }
      }
    },
    logout () {
      this.$auth.logout().then(() => this.$router.push('/login'))
    },
    async checkOMSettings (value = null) {
      const currentYear = value || this.latestWaterYear

      const om = Object.values(this.$store.state.omsettings.all).find(
        item => item.year === currentYear
      )
      if (!om) {
        const errorObject = {
          error: {
            data: {
              message: `OM Settings for ${currentYear} has not been set yet! Please add these in the O&M Settings page first!`
            }
          }
        }

        await this.$store.dispatch('omsettings/set_om_of_year', false)
        await this.$store.dispatch('omsettings/set_snack', errorObject)
        await this.$router.push('/omsettings')
        return false
      } else {
        await this.$store.dispatch('omsettings/set_om_of_year', om)
      }

      return om
    }
  }
}
