import globalActions from '../api/GlobalActions.js'
import globalMutations from '../api/GlobalMutations.js'
import globalGetters from '../api/GlobalGetters.js'

export const state = () => ({
  isStale: true,
  latest_fetch: '',
  type: 'announcements',
  pageLayout: 'StructureTable',
  canEdit: false,
  canView: false,
  effectedByYear: false,
  yearFields: [],
  aliasField: 'id',
  modelLabels: {
    single: 'Announcement',
    plural: 'Announcements'
  },
  filterOverrides: [],
  relations: [
  ],
  all: {},
  reference: {
    /* for showing paginated resources ... needs a bit more work. */
  },
  active: false,
  page: 1,
  pageCount: 0,
  pagination: {
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    itemsPerPage: 150,
    sort: true,
    sortBy: 'importance',
    page: 1,
    sortDesc: []
  },
  print_path: '',
  search: null,
  searchFields: ['title', 'body'],
  total: 0,
  print: false,
  export: false,
  bound: {
    id: null,
    title: null,
    body: '',
    limit_distribution_to: 'all',
    publish: true
  },
  filter: {
  },
  quickFilters: [],
  stub: {
  },
  // members are called contractors on the server
  include: 'dismissals',
  headers: {},
  notifications: {}
})

// Mutations ==================

export const mutations = {
  ...globalMutations
}

// Actions ====================

export const actions = {
  ...globalActions
}

// Getters ====================

export const getters = {
  ...globalGetters
}
