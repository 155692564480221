import globalActions from '../api/GlobalActions.js'
import globalMutations from '../api/GlobalMutations.js'
import globalGetters from '../api/GlobalGetters.js'

export const state = () => ({
  type: 'addressHistory',
  canEdit: false,
  canView: false,
  effectedByYear: false,
  yearFields: [],
  relations: [
    {
      name: 'contact',
      translate: 'contact',
      value: 'contacts',
      yearFields: []
    },
    {
      name: 'user',
      translate: 'user',
      value: 'users',
      yearFields: []
    }
  ],
  all: {},
  reference: {},
  active: false,
  page: 1,
  pageCount: 0,
  pagination: {
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    itemsPerPage: 150,
    sort: true,
    sortBy: 'updated_at',
    page: 1,
    sortDesc: ['updated_at']
  },
  print_path: '',
  total: 0,
  print: false,
  export: false,
  search: null,
  bound: {
    id: '',
    user: {},
    address: '',
    address_1: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
    updated_at: ''
  },
  stub: {
    user: {}
  },
  filter: {},
  include: '',
  headers: {},
  notifications: {}
})

export const mutations = {
  ...globalMutations
}

export const actions = {
  ...globalActions
}

export const getters = {
  ...globalGetters
}
