
import _ from 'lodash'
export default {
  name: 'CreateUsage',
  props: {
    usageData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      open: true,
      active: {},
      turnoff: false,
      producer: null,
      loading: false
    }
  },
  computed: {
    page () {
      return this.$store.getters['sectionSchemas/findbyname']('usages')
    },
    schemas () {
      return this.page.schemas
    },
    formSchema () {
      const schema = _.cloneDeep(this.schemas.find(s => s.key === 'usages_form'))

      if (this.turnoff) {
        console.log('TURNOFF')
        schema.headers = schema.headers.filter(h => !h.options.readonly_on_turnoff)
      }

      return schema
    },
    turnOffHeaders () {
      const schema = _.cloneDeep(this.schemas.find(s => s.key === 'usages_form'))

      if (this.turnoff) {
        schema.headers = schema.headers.filter(h => h.options.readonly_on_turnoff).map((h) => {
          h.type = 'textBlock'
          return h
        })
      }

      return schema
    }
  },
  mounted () {
    this.setActive()
  },
  methods: {
    async success () {
      if (this.objHas(this.usageData, 'flowUpdate') && this.usageData.flowUpdate === true) {
        const usageToClose = this.$store.getters['usages/find'](this.usageData.oldUsageId)
        if (usageToClose) {
          this.loading = true
          delete usageToClose.flowUpdate
          delete usageToClose.oldUsageId
          const cleanFormData = this.onlyIds(usageToClose)

          const obj = {
            id: this.usageData.oldUsageId,
            payload: { ...cleanFormData, off_date: this.usageData.on_date, off_time: this.usageData.on_time }
          }
          await this.$store.dispatch('usages/update', obj)
          this.loading = false
        }
      }

      this.$emit('success')
      this.$emit('closeUsageForm')
    },
    setActive () {
      const temp = { ...JSON.parse(JSON.stringify(this.$store.state.usages.bound)), ..._.cloneDeep(this.usageData) }

      if (temp.turnoff) {
        this.turnoff = true
      }

      delete temp.turnoff
      this.active = temp
    }
  }
}
