import globalActions from '../api/GlobalActions.js'
import globalMutations from '../api/GlobalMutations.js'
import globalGetters from '../api/GlobalGetters.js'

export const state = () => ({
  latest_fetch: '',
  type: 'states',
  canEdit: false,
  canView: false,
  effectedByYear: false,
  yearFields: [],
  modelLabels: {
    single: 'State',
    plural: 'States'
  },
  filterOverrides: [],
  aliasField: 'name',
  relations: [],
  all: {},
  reference: {
    /* for showing paginated resources ... needs a bit more work. */
  },
  active: false,
  page: 1,
  pageCount: 0,
  pagination: {
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    itemsPerPage: 100,
    sort: false,
    sortBy: 'name',
    page: 1,
    sortDesc: []
  },
  total: 0,
  print_path: '',
  print: false,
  export: false,
  search: null,
  searchFields: ['code', 'name'],
  bound: {},
  filter: {},
  quickFilters: [],
  stub: {
    contact: {},
    author: {}
  },
  // members are called contractors on the server
  include: '',
  headers: {},
  notifications: {}
})

// Mutations ==================

export const mutations = {
  ...globalMutations
}

// Actions ====================

export const actions = {
  ...globalActions
}

// Getters ====================

export const getters = {
  ...globalGetters
}
