import globalActions from '../api/GlobalActions.js'
import globalMutations from '../api/GlobalMutations.js'
import globalGetters from '../api/GlobalGetters.js'

export const state = () => ({
  latest_fetch: '',
  type: 'diversionPoints',
  effectedByYear: false,
  yearFields: [],
  aliasField: 'name',
  modelLabels: {
    single: 'Diversion Point',
    plural: 'Diversion Points'
  },
  filterOverrides: [],
  relations: [
    {
      name: 'producer',
      label: 'Producer',
      compactLabel: 'Producer',
      translate: 'producer',
      value: 'contacts',
      storename: 'contacts',
      yearFields: [],
      export: {
        single: true,
        list: true
      },
      multiple: false,
      filterOnAttributes: true
    },
    {
      name: 'farmunit',
      label: 'Farmunit',
      compactLabel: 'Farmunit',
      translate: 'farmunit',
      value: 'farmunits',
      storename: 'farmunits',
      multiple: false,
      yearFields: [],
      export: {
        single: true,
        list: true
      },
      filterOnAttributes: true
    },
    {
      name: 'ditch',
      label: 'Ditch',
      compactLabel: 'Ditch',
      translate: 'ditch',
      value: 'ditches',
      storename: 'ditches',
      multiple: false,
      yearFields: [],
      export: {
        single: true,
        list: true
      },
      filterOnAttributes: true
    },
    {
      name: 'division',
      label: 'Division',
      compactLabel: 'Division',
      translate: 'division',
      value: 'divisions',
      storename: 'divisions',
      multiple: false,
      yearFields: [],
      export: {
        single: true,
        list: true
      },
      filterOnAttributes: true
    },
    {
      name: 'orders',
      label: 'Unfulfilled Orders',
      compactLabel: 'Unfulfilled Orders',
      translate: 'orders',
      value: 'orders',
      storename: 'orders',
      multiple: true,
      yearFields: ['run_date'],
      export: {
        single: true,
        list: false
      },
      filterOnAttributes: true
    },
    {
      name: 'usages',
      label: 'Orders',
      compactLabel: 'Orders',
      translate: 'usages',
      value: 'usages',
      storename: 'usages',
      multiple: true,
      yearFields: ['on_date'],
      export: {
        single: true,
        list: false
      },
      filterOnAttributes: true
    }
  ],
  all: {},
  reference: {},
  active: false,
  page: 1,
  pageCount: 0,
  pagination: {
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    itemsPerPage: 50,
    sort: true,
    sortBy: 'farmunit.producer.full_name',
    page: 1,
    sortDesc: []
  },
  print_path: '',
  search: null,
  searchFields: ['name'],
  total: 0,
  print: false,
  export: false,
  bound: {},
  filter: {},
  quickFilters: [],
  stub: {
    ditch: {},
    division: {},
    farmunit: {},
    farmunits: [],
    orders: [],
    usages: []
  },
  // members are called contractors on the server
  include: 'farmunit,ditch,orders,usages',
  headers: {},
  notifications: {}
})

// Mutations ==================

export const mutations = {
  ...globalMutations
}

// Actions ====================

export const actions = {
  ...globalActions,
  async determineDitch ({ commit, state, getters, dispatch, rootState }) {
    const email = rootState.auth.user.data.email
    let division = 1

    switch (email) {
      case '#1@gid-mt.com':
        division = 1 // if (x === 'value1')
        break
      case '#2@gid-mt.com':
        division = 2
        break
      case '#3@gid-mt.com':
        division = 3
        break
      case '#5@gid-mt.com':
        division = 5
        break
      case '#6@gid-mt.com':
        division = 6
        break
      case '#7@gid-mt.com':
        division = 7
        break
      case '#8@gid-mt.com':
        division = 8
        break
      case '#9@gid-mt.com':
        division = 9
        break
      default:
        division = 1
        break
    }

    commit('updateFilter', {
      key: 'division',
      value: division
    })
    await dispatch('list')
  },
  async set_orders_card_active (
    { commit, state, getters, dispatch, rootState },
    id
  ) {
    await commit('active', id)

    const obj = {
      view: 'diversionPoints',
      customComponent: 'diversionPointOrder',
      id
    }
    dispatch('system/active_view', obj, {
      root: true
    })
  }
}

// Getters ====================

export const getters = {
  ...globalGetters
}
