import axios from 'axios'
import globalActions from '../api/GlobalActions.js'
import globalMutations from '../api/GlobalMutations.js'
import globalGetters from '../api/GlobalGetters.js'

export const state = () => ({
  latest_fetch: '',
  type: 'users',
  pageLayout: 'LayoutStructureTable',
  effectedByYear: false,
  yearFields: [],
  aliasField: 'full_name',
  status: '',
  user: {},
  active: false,
  all: {},
  filterOverrides: [],
  relations: [
    {
      name: 'divisions',
      translate: 'divisions',
      label: 'Divisions',
      value: 'divisions',
      storename: 'divisions',
      multiple: true,
      export: true,
      yearFields: []
    }
  ],
  modelLabels: {
    single: 'User',
    plural: 'Users'
  },
  reference: {
    /* for prefetching not working yet. */
  },
  page: 1,
  pagination: {
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    itemsPerPage: null,
    sort: false,
    sortBy: 'name',
    page: 1,
    sortDesc: []
  },
  total: 0,
  print: false,
  export: false,
  search: null,
  searchFields: ['name', 'first_name', 'last_name', 'company'],
  bound: {},
  filter: {},
  quickFilters: [],
  stub: {
    audits: [],
    notes: []
  },
  include: '',
  headers: {},
  notifications: {}
})

export const getters = {
  ...globalGetters,
  user: state => state.user,
  stateTenant (state) {
    if (typeof state.user === 'object' && state.user !== null) {
      //   if (state.user.roles.includes("state")) {
      //     return true
      //   } else {
      //     return false
      //   }
    } else {
      return false
    }
  },
  permissions: (state) => {
    return state.user.can
  }
  // isProfileLoaded: state => !!state.profile.name,
}

export const actions = {
  ...globalActions,
  async userRequest ({ commit, dispatch }) {
    commit('userRequest')
    let response
    try {
      response = await axios.get('api/me')
    } catch (ex) {
      commit('userError', ex)
      dispatch('authLogout')
    }

    commit('userSuccess', response.data)
  }
}

export const mutations = {
  ...globalMutations,
  userRequest: (state) => {
    state.status = 'loading'
  },
  userSuccess: (state, payload) => {
    state.status = 'success'
    state.user = payload.data
  },
  userError: (state, err) => {
    state.status = err
  }
}
