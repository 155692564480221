import globalActions from '../api/GlobalActions.js'
import globalMutations from '../api/GlobalMutations.js'
import globalGetters from '../api/GlobalGetters.js'

export const state = () => ({
  latest_fetch: '',
  type: 'farmunits',
  pageLayout: 'LayoutStructureTable',
  canEdit: false,
  canView: false,
  effectedByYear: false,
  yearFields: [],
  aliasField: 'unit_id',
  modelLabels: {
    single: 'Farm Unit',
    plural: 'Farm Units'
  },
  filterHideOverrides: [],
  relations: [
    {
      name: 'division',
      label: 'Division',
      compactLabel: 'Division',
      translate: 'division',
      value: 'divisions',
      storename: 'divisions',
      multiple: false,
      yearFields: [],
      export: {
        single: true,
        list: true
      },
      filterOnAttributes: true
    },
    {
      name: 'owners',
      label: 'Owners(s)',
      compactLabel: 'Owners(s)',
      translate: 'owners',
      value: 'contacts',
      storename: 'contacts',
      multiple: true,
      yearFields: [],
      export: {
        single: true,
        list: true
      },
      filterOnAttributes: true
    },
    {
      name: 'producer',
      label: 'Producer',
      compactLabel: 'Producer',
      translate: 'producer',
      value: 'contacts',
      storename: 'contacts',
      multiple: false,
      yearFields: [],
      export: {
        single: true,
        list: true
      },
      filterOnAttributes: true
    },
    {
      name: 'secondary',
      label: 'Secondary Producer',
      compactLabel: 'Secondary Producer',
      translate: 'secondary',
      value: 'contacts',
      storename: 'contacts',
      multiple: true,
      yearFields: [],
      export: {
        single: true,
        list: true
      },
      filterOnAttributes: true
    },
    {
      name: 'voter',
      label: 'Voter',
      compactLabel: 'Voter',
      translate: 'voter',
      value: 'contacts',
      storename: 'contacts',
      multiple: false,
      yearFields: [],
      export: {
        single: true,
        list: true
      },
      filterOnAttributes: true
    },
    {
      name: 'flags',
      label: 'Flags',
      compactLabel: 'Flags',
      translate: 'flags',
      value: 'flags',
      storename: 'flags',
      multiple: true,
      yearFields: [],
      export: {
        single: true,
        list: true
      },
      filterOnAttributes: true
    },
    {
      name: 'ditches',
      label: 'Ditches',
      compactLabel: 'Ditches',
      translate: 'ditches',
      value: 'ditches',
      storename: 'ditches',
      multiple: true,
      yearFields: [],
      export: {
        single: true,
        list: true
      },
      filterOnAttributes: true
    },
    {
      name: 'orders',
      label: 'Unfulfilled Orders',
      compactLabel: 'Unfulfilled Orders',
      translate: 'orders',
      value: 'orders',
      storename: 'orders',
      multiple: true,
      yearFields: ['run_date'],
      export: {
        single: true,
        list: false
      },
      filterOnAttributes: true
    },
    {
      name: 'usages',
      label: 'Orders',
      compactLabel: 'Orders',
      translate: 'usages',
      value: 'usages',
      storename: 'usages',
      multiple: true,
      yearFields: ['on_date'],
      export: {
        single: true,
        list: false
      },
      filterOnAttributes: true
    },
    {
      name: 'diversionPoints',
      label: 'T/O',
      compactLabel: 'T/O',
      translate: 'diversionPoints',
      value: 'diversionPoints',
      storename: 'diversionPoints',
      multiple: true,
      yearFields: [],
      export: {
        single: true,
        list: true
      },
      filterOnAttributes: true
    },
    {
      name: 'ownershipHistory',
      label: 'Ownership History',
      compactLabel: 'Ownership History',
      translate: 'ownershipHistory',
      value: 'ownershipHistory',
      storename: 'ownershipHistory',
      multiple: true,
      yearFields: [],
      export: {
        single: true,
        list: false
      },
      filterOnAttributes: false
    },
    {
      name: 'producerHistory',
      label: 'Producer History',
      compactLabel: 'Producer History',
      translate: 'producerHistory',
      value: 'producerHistory',
      storename: 'producerHistory',
      yearFields: [],
      multiple: true,
      export: {
        single: true,
        list: false
      },
      filterOnAttributes: false
    }
  ],
  all: {},
  reference: {},
  active: false,
  page: 1,
  pageCount: 0,
  pagination: {
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    itemsPerPage: 50,
    sort: false,
    sortBy: 'unit_id',
    page: 1,
    sortDesc: []
  },
  total: 0,
  print: false,
  print_path: '',
  export: false,
  search: '',
  searchFields: ['unit_id'],
  bound: {},
  stub: {
    omsettings: [],
    owners: [],
    division: {},
    producer: {},
    secondary: [],
    flags: [],
    diversionPoints: [],
    usages: [],
    voter: {},
    ownershipHistory: [],
    producerHistory: []
  },
  filter: {},
  quickFilters: [],
  include:
    'flags,owners,producer,secondary,division,ditches,ownershipHistory,producerHistory,orders,usages',
  headers: {},
  notifications: {}
})

// Mutations ==================

export const mutations = {
  ...globalMutations
}

// Actions ====================

export const actions = {
  ...globalActions
}

// Getters ====================

export const getters = {
  ...globalGetters
}
