import globalActions from '../api/GlobalActions.js'
import globalMutations from '../api/GlobalMutations.js'
import globalGetters from '../api/GlobalGetters.js'

export const state = () => ({
  latest_fetch: '',
  type: 'flags',
  pageLayout: 'LayoutStructureTable',
  canEdit: false,
  canView: false,
  effectedByYear: false,
  yearFields: [],
  aliasField: 'name',
  modelLabels: {
    single: 'Flag',
    plural: 'Flags'
  },
  filterOverrides: [],
  relations: [
    {
      name: 'contacts',
      label: 'Contacts',
      compactLabel: 'Contacts',
      translate: 'contacts',
      value: 'contacts',
      storename: 'contacts',
      multiple: true,
      yearFields: [],
      export: {
        single: true,
        list: true
      },
      filterOnAttributes: true
    },
    {
      name: 'farmunits',
      label: 'Farmunit',
      compactLabel: 'Farmunit',
      translate: 'farmunits',
      value: 'farmunits',
      storename: 'farmunits',
      multiple: true,
      yearFields: [],
      export: {
        single: true,
        list: true
      },
      filterOnAttributes: true
    }
  ],
  all: {},
  reference: {
    /* for showing paginated resources ... needs a bit more work. */
  },
  active: false,
  page: 1,
  pageCount: 0,
  pagination: {
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    itemsPerPage: 150,
    sort: true,
    sortBy: 'name',
    page: 1,
    sortDesc: []
  },
  print_path: '',
  search: null,
  searchFields: ['name'],
  total: 0,
  print: false,
  export: false,
  quickFilters: [],
  bound: {
    name: '',
    contacts: [],
    farmunits: []
  },
  filter: {
    contacts: [],
    farmunits: []
  },
  stub: {
    contacts: [],
    farmunits: []
  },
  // members are called contractors on the server
  include: 'contacts,farmunits',
  headers: {},
  notifications: {}
})

// Mutations ==================

export const mutations = {
  ...globalMutations
}

// Actions ====================

export const actions = {
  ...globalActions
}

// Getters ====================

export const getters = {
  ...globalGetters
}
