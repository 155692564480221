import globalActions from '../api/GlobalActions.js'
import globalMutations from '../api/GlobalMutations.js'
import globalGetters from '../api/GlobalGetters.js'

export const state = () => ({
  latest_fetch: '',
  type: 'divisions',
  pageLayout: 'LayoutStructureTable',
  effectedByYear: false,
  yearFields: [],
  canEdit: false,
  canView: false,
  aliasField: 'name',
  modelLabels: {
    single: 'Division',
    plural: 'Divisions'
  },
  filterOverrides: [],
  relations: [
    {
      name: 'farmunits',
      label: 'Farmunit',
      compactLabel: 'Farmunit',
      translate: 'farmunits',
      value: 'farmunits',
      storename: 'farmunits',
      multiple: true,
      yearFields: [],
      export: {
        single: true,
        list: true
      },
      filterOnAttributes: true
    },
    {
      name: 'diversionPoints',
      label: 'Diversion Points',
      compactLabel: 'T/O',
      translate: 'diversionPoints',
      value: 'diversionPoints',
      storename: 'diversionPoints',
      multiple: true,
      yearFields: [],
      export: {
        single: true,
        list: true
      },
      filterOnAttributes: true
    }
  ],
  all: {},
  reference: {
    /* for showing paginated resources ... needs a bit more work. */
  },
  active: false,
  page: 1,
  pageCount: 0,
  pagination: {
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    itemsPerPage: 150,
    sort: true,
    sortBy: 'name',
    page: 1,
    sortDesc: []
  },
  print_path: '',
  search: null,
  searchFields: ['name'],
  total: 0,
  print: false,
  export: false,
  // this will need to by dynamic based on user config valuese
  bound: {},
  quickFilters: [],
  filter: {
  },
  stub: {
    ditches: [],
    farmunits: [],
    diversionPoints: []
  },
  // members are called contractors on the server
  include: '',
  headers: {},
  notifications: {}
})

// Mutations ==================

export const mutations = {
  ...globalMutations
}

// Actions ====================

export const actions = {
  ...globalActions
}

// Getters ====================

export const getters = {
  ...globalGetters
}
