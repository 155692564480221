const COMPONENT_MAP = {
  autocomplete: 'BaseFormAutocompleteElement',
  text: 'BaseFormTextElement',
  hidden: 'BaseFormHiddenElement',
  verboseText: 'BaseFormVerboseTextElement',
  verboseParent: 'BaseFormVerboseParent',
  money: 'moneyElement',
  rawMoney: 'rawMoneyElement',
  select: 'BaseFormSelectElement',
  subSelect: 'BaseFormSubSelectElement',
  verboseSelect: 'BaseFormVerboseSelectElement',
  checkbox: 'checkboxElement',
  date: 'BaseFormDateElement',
  switch: 'BaseFormSwitchElement',
  dateWY: 'dateWYElement',
  textarea: 'BaseFormTextareaElement',
  wysiwyg: 'WysiwygElement',
  phone: 'BaseFormPhoneElement',
  email: 'emailElement',
  states: 'statesElement',
  time: 'BaseFormTimeElement',
  timePickerAmPm: 'BaseFormTimePickerAmPm',
  time_picker: 'BaseFormTimePicker',
  timeInterval: 'timeIntervalElement',
  readOnly: 'BaseFormReadOnlyElement',
  boolean: 'BaseFormBooleanElement',
  attachment: 'attachmentElement',
  heading: 'BaseFormHeadingElement',
  textBlock: 'BaseFormTextBlockElement',
  producerTurnoutList: 'BaseFormProducerTurnoutList',
  orderInstructions: 'BlocksOrderInstructions'
}

export function getComponent (type) {
  return COMPONENT_MAP[type]
}
