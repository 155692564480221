const middleware = {}

middleware['auth-admin'] = require('../middleware/auth-admin.js')
middleware['auth-admin'] = middleware['auth-admin'].default || middleware['auth-admin']

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['history'] = require('../middleware/history.js')
middleware['history'] = middleware['history'].default || middleware['history']

middleware['isAdmin'] = require('../middleware/isAdmin.js')
middleware['isAdmin'] = middleware['isAdmin'].default || middleware['isAdmin']

middleware['waterYears'] = require('../middleware/waterYears.js')
middleware['waterYears'] = middleware['waterYears'].default || middleware['waterYears']

export default middleware
