// State ======================
import globalActions from '../api/GlobalActions.js'
import globalMutations from '../api/GlobalMutations.js'
import globalGetters from '../api/GlobalGetters.js'

export const state = () => ({
  type: 'usages',
  canEdit: false,
  canView: false,
  effectedByYear: true,
  yearFields: [],
  relations: [
    {
      name: 'farmunit',
      label: 'Farmunit',
      compactLabel: 'Farmunit',
      translate: 'farmunit',
      value: 'farmunits',
      storename: 'farmunits',
      multiple: false,
      yearFields: [],
      export: {
        single: true,
        list: true
      },
      filterOnAttributes: true
    },
    {
      name: 'user',
      label: 'User',
      compactLabel: 'User',
      translate: 'user',
      value: 'users',
      storename: 'users',
      multiple: false,
      yearFields: [],
      export: {
        single: true,
        list: true
      },
      filterOnAttributes: false
    },
    {
      name: 'ditch',
      label: 'Ditch',
      compactLabel: 'Ditch',
      translate: 'ditch',
      value: 'ditches',
      storename: 'ditches',
      multiple: false,
      yearFields: [],
      export: {
        single: true,
        list: true
      },
      filterOnAttributes: true
    },
    {
      name: 'diversionPoint',
      label: 'T/O',
      compactLabel: 'T/O',
      translate: 'diversionPoint',
      value: 'diversionPoints',
      storename: 'diversionPoints',
      multiple: false,
      yearFields: [],
      export: {
        single: true,
        list: true
      },
      filterOnAttributes: true
    },
    {
      name: 'division',
      label: 'Division',
      compactLabel: 'Division',
      translate: 'division',
      value: 'divisions',
      storename: 'divisions',
      multiple: false,
      yearFields: [],
      export: {
        single: true,
        list: true
      },
      filterOnAttributes: true
    },
    {
      name: 'producer',
      label: 'Producer',
      compactLabel: 'Producer',
      translate: 'producer',
      value: 'contacts',
      storename: 'contacts',
      yearFields: [],
      export: {
        single: true,
        list: true
      },
      multiple: false,
      filterOnAttributes: true
    }
  ],
  all: {},
  reference: {
    /* for showing paginated resources ... needs a bit more work. */
  },
  active: false,
  startDate: null,
  page: 1,
  pageCount: 0,
  pagination: {
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    itemsPerPage: 150,
    sort: true,
    sortBy: 'on_date',
    page: 1,
    sortDesc: []
  },
  total: 0,
  print: false,
  export: false,
  print_path: '',
  bound: {
    // hours: "",
    // run_date: "",
    // flow: "",
    // ditch: "",
    // diversionPoint: "",
    // farmunit: "",
    // producer: "",
    // usageArray: [],
  },
  filter: {
    // hours: "",
    // flow: "",
    // ditch: "",
    // diversionPoint: "",
    // farmunit: "",
  },
  stub: {
    ditch: []
  },
  notifications: {},
  include: 'farmunit,ditch,user,diversionPoint',
  headers: {}
})

// Mutations ==================

export const mutations = {
  ...globalMutations,
  setStartDate: (state, date) => {
    state.startDate = date
  }
}

// Actions ====================

export const actions = {
  ...globalActions
}

// Getters ====================

export const getters = {
  ...globalGetters
}
