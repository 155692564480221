import globalActions from '../api/GlobalActions.js'
import globalMutations from '../api/GlobalMutations.js'
import globalGetters from '../api/GlobalGetters.js'

export const state = () => ({
  latest_fetch: '',
  type: 'ownershipHistory',
  canEdit: false,
  canView: false,
  effectedByYear: false,
  yearFields: [],
  aliasField: 'id',
  modelLabels: {
    single: 'Ownership History',
    plural: 'Ownership History'
  },
  relations: [
    {
      name: 'farmunit',
      translate: 'farmunit',
      label: 'Farm Unit',
      value: 'farmunits',
      storename: 'farmunits',
      yearFields: [],
      compactLabel: 'Farmunit',
      export: false,
      filterOnAttributes: false
    }
  ],
  all: {},
  reference: {},
  active: false,
  page: 1,
  pageCount: 0,
  pagination: {
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    itemsPerPage: 150,
    sort: true,
    sortBy: 'updated_at',
    page: 1,
    sortDesc: ['updated_at']
  },
  print_path: '',
  total: 0,
  print: false,
  export: false,
  search: null,
  searchFields: ['owner'],
  bound: {
    // id: "",
    // farmunit_id: "",
    // started_at: "",
    // ended_at: "",
  },
  stub: {},
  filter: {},
  quickFilters: [],
  include: '',
  headers: {},
  notifications: {}
})

export const mutations = {
  ...globalMutations
}

export const actions = {
  ...globalActions
}

export const getters = {
  ...globalGetters
}
