import globalActions from '../api/GlobalActions.js'
import globalMutations from '../api/GlobalMutations.js'
import globalGetters from '../api/GlobalGetters.js'

export const state = () => ({
  latest_fetch: '',
  type: 'contacts',
  pageLayout: 'LayoutStructureTable',
  canEdit: false,
  canView: false,
  effectedByYear: false,
  yearFields: [],
  modelLabels: {
    single: 'Contact',
    plural: 'Contacts'
  },
  inlineEdit: true,
  filterHideOverrides: ['notes', 'addressHistory', 'ownsDp', 'ownsOrders', 'producesOrders'],
  aliasField: 'full_name',
  relations: [
    {
      name: 'ownsFu',
      label: 'Owner: Farmunit',
      compactLabel: 'Owner: Farmunit',
      translate: 'farmowner',
      value: 'farmunits',
      storename: 'farmunits',
      multiple: true,
      yearFields: [],
      export: {
        single: true,
        list: true
      },
      filterOnAttributes: true
    },
    {
      name: 'producesFu',
      translate: 'producesFu',
      label: 'Producer: Farmunit',
      compactLabel: 'Producer: Farmunit',
      value: 'farmunits',
      storename: 'farmunits',
      multiple: true,
      yearFields: [],
      export: {
        single: true,
        list: true
      },
      filterOnAttributes: true
    },
    {
      name: 'secondaryFu',
      translate: 'farmsecondary',
      label: 'Secondary Producer: Farmunit',
      compactLabel: 'Farmunit',
      value: 'farmunits',
      storename: 'farmunits',
      multiple: true,
      yearFields: [],
      export: {
        single: true,
        list: true
      },
      filterOnAttributes: true
    },
    {
      name: 'flags',
      label: 'Flags',
      compactLabel: 'Flag',
      translate: 'flags',
      value: 'flags',
      storename: 'flags',
      multiple: true,
      yearFields: [],
      export: {
        single: true,
        list: true
      },
      filterOnAttributes: true
    },
    {
      name: 'notes',
      label: 'Notes',
      compactLabel: 'Notes',
      translate: 'notes',
      value: 'notes',
      storename: 'notes',
      multiple: true,
      yearFields: [],
      export: {
        single: true,
        list: false
      },
      filterOnAttributes: false
    },
    {
      name: 'division',
      translate: 'divisions',
      label: 'Divisions',
      compactLabel: 'Divisions',
      value: 'divisions',
      storename: 'divisions',
      multiple: true,
      export: {
        single: false,
        list: false
      },
      yearFields: [],
      filterOnAttributes: false
    },
    {
      name: 'ownsDp',
      translate: 'diversionPoints',
      label: 'Owns: Turnouts',
      compactLabel: 'Turnouts',
      value: 'diversionPoints',
      storename: 'diversionPoints',
      multiple: true,
      export: {
        single: true,
        list: true
      },
      yearFields: [],
      filterOnAttributes: false
    },
    {
      name: 'producesDp',
      translate: 'diversionPoints',
      label: 'Producing On: Turnouts',
      compactLabel: 'Turnouts',
      value: 'diversionPoints',
      storename: 'diversionPoints',
      multiple: true,
      export: {
        single: true,
        list: true
      },
      yearFields: [],
      filterOnAttributes: true
    },
    {
      name: 'addressHistory',
      label: 'Address History',
      compactLabel: 'Address History',
      translate: 'addressHistory',
      value: 'addressHistory',
      storename: 'addressHistory',
      multiple: true,
      export: {
        single: true,
        list: false
      },
      filterOnAttributes: false
    },
    {
      name: 'ownsOrders',
      translate: 'orders',
      label: 'Unfulfilled Orders on Owned Turnouts',
      compactLabel: 'Unfulfilled Orders',
      value: 'orders',
      storename: 'orders',
      multiple: true,
      export: {
        single: false,
        list: false
      },
      yearFields: [],
      filterOnAttributes: true
    },
    {
      name: 'producesOrders',
      translate: 'orders',
      label: 'Unfulfilled Orders on Producing Turnouts',
      compactLabel: 'Unfulfilled Orders',
      value: 'orders',
      storename: 'orders',
      multiple: true,
      export: {
        single: true,
        list: false
      },
      yearFields: [],
      filterOnAttributes: true
    }
  ],
  all: {},
  reference: {
    /* for showing paginated resources ... needs a bit more work. */
  },
  active: false,
  page: 1,
  pageCount: 0,
  pagination: {
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    itemsPerPage: 150,
    sort: true,
    sortBy: 'full_name',
    page: 1,
    sortDesc: []
  },
  print_path: '',
  total: 0,
  print: false,
  export: false,
  search: null,
  searchFields: ['full_name', 'company', 'first_name', 'last_name'],
  bound: {},
  stub: {
    ownsFu: [],
    producesFu: [],
    ownsDp: [],
    producesDp: [],
    usages: [],
    addressHistory: [],
    notes: [],
    flags: []
  },
  quickFilters: [
    {
      label: 'Contact Type',
      name: 'type',
      order: 1,
      value: [
        {
          label: 'Is Producer',
          desc: '',
          order: 1,
          schema: { producesDp: 'any(true)' }
        },
        {
          label: 'Is Owner',
          desc: '',
          order: 2,
          schema: { ownsFu: 'any(true)' }
        }
      ]
    }
  ],
  filter: {},
  include: 'farmowner,farmproducer,farmproducer.flags,farmsecondary,flags,notes,addressHistory',
  headers: {},
  notifications: {}
})

export const mutations = {
  ...globalMutations
}

export const actions = {
  ...globalActions
}

export const getters = {
  ...globalGetters
}
