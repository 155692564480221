// State ======================
import globalActions from '../api/GlobalActions.js'
import globalMutations from '../api/GlobalMutations.js'
import globalGetters from '../api/GlobalGetters.js'

export const state = () => ({
  type: 'orders',
  pageLayout: 'StructureTable',
  canEdit: false,
  canView: false,
  effectedByYear: true,
  yearFields: ['run_date'],
  relations: [
    {
      name: 'farmunit',
      label: 'Farmunit',
      compactLabel: 'Farmunit',
      translate: 'farmunit',
      value: 'farmunits',
      storename: 'farmunits',
      multiple: false,
      yearFields: [],
      export: {
        single: true,
        list: true
      },
      filterOnAttributes: true
    },
    {
      name: 'user',
      label: 'User',
      compactLabel: 'User',
      translate: 'user',
      value: 'users',
      storename: 'users',
      multiple: false,
      yearFields: [],
      export: {
        single: true,
        list: true
      },
      filterOnAttributes: false
    },
    {
      name: 'ditch',
      label: 'Ditch',
      compactLabel: 'Ditch',
      translate: 'ditch',
      value: 'ditches',
      storename: 'ditches',
      multiple: false,
      yearFields: [],
      export: {
        single: true,
        list: true
      },
      filterOnAttributes: true
    },
    {
      name: 'diversionPoint',
      label: 'T/O',
      compactLabel: 'T/O',
      translate: 'diversionPoint',
      value: 'diversionPoints',
      storename: 'diversionPoints',
      multiple: false,
      yearFields: [],
      export: {
        single: true,
        list: true
      },
      filterOnAttributes: true
    },
    {
      name: 'division',
      label: 'Division',
      compactLabel: 'Division',
      translate: 'division',
      value: 'divisions',
      storename: 'divisions',
      multiple: false,
      yearFields: [],
      export: {
        single: true,
        list: true
      },
      filterOnAttributes: true
    },
    {
      name: 'producer',
      label: 'Producer',
      compactLabel: 'Producer',
      translate: 'producer',
      value: 'contacts',
      storename: 'contacts',
      yearFields: [],
      export: {
        single: true,
        list: true
      },
      multiple: false,
      filterOnAttributes: true
    }
  ],
  all: {},
  reference: {
    /* for showing paginated resources ... needs a bit more work. */
  },
  active: false,
  page: 1,
  pageCount: 0,
  pagination: {
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    itemsPerPage: 150,
    sort: true,
    sortBy: 'run_date',
    page: 1,
    sortDesc: []
  },
  total: 0,
  print: false,
  export: false,
  print_path: '',
  stub: {
    ditch: {},
    farmunit: {},
    diversionPoint: {},
    user: {},
    producer: {}
  },

  bound: {
    order_no: '',
    run_date: '',
    notes: '',
    ditch: '',
    diversionPoint: '',
    farmunit: '',
    producer: '',
    flow: '',
    type: ''
  },
  filter: {
    attr_between_date: {
      run_date: {
        start: new Date().getFullYear() + '-01-01',
        end: new Date().getFullYear() + '-12-31'
      }
    }
    // notes: "",
    // ditch: "",
    // diversionPoint: "",
    // farmunit: "",
  },
  include: 'farmunit,ditch,user,diversionPoint,producer',
  headers: {},
  notifications: {}
})

// Mutations ==================

export const mutations = {
  ...globalMutations
}

// Actions ====================

export const actions = {
  ...globalActions,
  async assignedToMe ({ commit, state, getters, dispatch, rootState }) {
    const userId = [rootState.auth.user.data.id]

    commit('updateBound', {
      key: 'users',
      value: userId
    })
    await dispatch('list')
  }
}

// Getters ====================

export const getters = {
  ...globalGetters
}
