
import _ from 'lodash'

export default {
  name: 'CreateOrder',
  props: {
    orderData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      open: true,
      active: {},
      producer: null
    }
  },
  computed: {
    page () {
      return this.$store.getters['sectionSchemas/findbyname']('orders')
    },
    schemas () {
      return this.page.schemas
    },
    currentProducer () {
      return this.$store.getters['contacts/find'](this.active.producer)
    },
    preventSubmit () {
      return this.isEmpty(this.active.run_date) ||
          this.isEmpty(this.active.run_time) ||
          this.isEmpty(this.active.diversionPoint) ||
          this.isEmpty(this.active.farmunit) ||
          this.isEmpty(this.active.flow) ||
          this.isEmpty(this.active.type)
    },
    formSchema () {
      const schema = _.cloneDeep(this.schemas.find(s => s.key === 'orders_form'))
      let headers = schema.headers

      headers = headers.map((header) => {
        if (this.objHas(this.orderData, 'isLocked') && this.orderData.isLocked) {
          if (header.options.propbindings.isLockable) {
            header.type = 'textBlock'
            return header
          } else if (header.options.propbindings.showWhenUnlocked) {
            return null
          }
        } else if (header.options.propbindings.hideWhenUnlocked) {
          return null
        }

        return header
      })
        .filter(item => item)

      if (this.isEmpty(this.active.diversionPoint)) {
        headers = headers.filter(h => !h.options.propbindings.isOrderDetail)
      }

      schema.headers = headers
      return schema
    },
    waterYear () {
      return this.$store.getters['system/getWaterYear']
    }
  },
  watch: {
    'active.producer': {
      handler (n, o) {
        this.setActive()
        this.active.producer = n
        this.producer = this.currentProducer
      },
      immediate: true
    },
    'active.type': {
      handler (n) {
        this.$set(this.active, 'type', n)
        this.$set(this.active, 'order_no', this.generateOrderNo())
      }
    }
  },
  mounted () {
    this.setActive()
  },
  methods: {
    generateOrderNo () {
      const orderNumbers = Object.values(this.$store.getters['orders/getAll'])
        .filter((o) => {
          if (!this.isEmpty(this.active.diversionPoint)) {
            return o.diversionPoint === this.active.diversionPoint
          }
          return true
        })
        .filter(o => !this.isEmpty(o.order_no))
        .map(o => Number(o.order_no.split('_').pop()))
        .filter(i => !isNaN(i))

      const relatedUsage = this.getRelatedUsage(
        typeof this.active.producer === 'object' ? this.active.producer?.id : this.active.producer,
        typeof this.active.diversionPoint === 'object' ? this.active.diversionPoint?.id : this.active.diversionPoint,
        typeof this.active.ditch === 'object' ? this.active.ditch?.id : this.active.ditch
      )

      let max = 1
      if (this.active.type !== 'On' && relatedUsage) {
        max = relatedUsage.order_no.split('_').pop()
      } else if (!this.isEmpty(orderNumbers)) {
        max = Math.max(...orderNumbers)
        if (this.active.type === 'On') {
          max = max + 1
        }
      }

      return `${
              `${this.waterYear}`.substring(2)}_${this.active.diversionPoint}_${max}${
              !this.isEmpty(this.active.type) && this.active.type !== 'On' ? `_${this.active.type}` : ''
          }`
    },
    getRelatedUsage (producer, diversionPoint, ditch) {
      const allUsages = this.$store.getters['usages/standardized']
      const filteredUsages = allUsages.filter((usage) => {
        return (typeof usage.producer === 'object' ? usage.producer.id === producer : usage.producer === producer) &&
                  usage.diversionPoint.id === diversionPoint &&
                  usage.ditch.id === ditch &&
                  (
                    this.isEmpty(usage.off_date) ||
                      new Date(`${usage.off_date}T${usage.off_time}`) > new Date()
                  )
      })
      return _.first(filteredUsages.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))) || null
    },
    success () {
      this.$emit('closeOrderForm')
    },
    setActive () {
      this.active = {
        ...JSON.parse(JSON.stringify(this.$store.state.orders.bound)),
        ..._.cloneDeep(this.orderData)
      }
    },
    emitUp (emittedValue) {
      if (emittedValue.event) {
        this[emittedValue.event](emittedValue.value)
      }
    },
    showProducerTurnoutList (producerId) {
      console.log('showProducerTurnoutList', { producerId })
    },
    savedAndNowNew () {
      this.active = {
        ...JSON.parse(JSON.stringify(this.$store.state.orders.bound)),
        ...{
          producer: this.active.producer,
          diversionPoint: this.active.diversionPoint,
          farmunit: this.active.farmunit
        }
      }

      this.open = true
    },
    dpSelected (dp) {
      console.log('dpSelected')
      this.$nextTick(() => {
        this.$set(this.active, 'diversionPoint', dp.id)
        this.$set(this.active, 'farmunit', dp.farmunit.id)
        this.$set(this.active, 'ditch', dp.ditch.id)
        this.$set(this.active, 'division', dp.division.id)
        this.$set(this.active, 'completed', false)
        this.$set(this.active, 'order_no', this.generateOrderNo())
      })
    },
    setFlow (flow) {
      console.log('setFlow')
      this.$nextTick(() => {
        this.$set(this.active, 'flow', flow)
      })
    },
    dpRemoved () {
      console.log('dpRemoved')
      this.$nextTick(() => {
        this.$set(this.active, 'diversionPoint', '')
        this.$set(this.active, 'farmunit', '')
        this.$set(this.active, 'type', '')
        this.$set(this.active, 'flow', '')
        this.$set(this.active, 'ditch', '')
        this.$set(this.active, 'division', '')
        this.$set(this.active, 'order_no', '')
      })
    },
    setType (type) {
      console.log('setType')
      this.$nextTick(() => {
        this.$set(this.active, 'type', type)
        this.$set(this.active, 'order_no', this.generateOrderNo())
      })
    }

  }
}
