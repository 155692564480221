// State ======================
import Vue from 'vue'
import moment from 'moment'
import globalActions from '../api/GlobalActions.js'
import globalMutations from '../api/GlobalMutations.js'
import globalGetters from '../api/GlobalGetters.js'

export const state = () => ({
  type: 'logo',
  pageLayout: 'StructureTable',
  canEdit: false,
  canView: false,
  effectedByYear: true,
  yearFields: [],
  relations: [
  ],
  all: {},
  reference: {
    /* for showing paginated resources ... needs a bit more work. */
  },
  active: false,
  page: 1,
  pageCount: 0,
  pagination: {
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    itemsPerPage: 150,
    sort: true,
    sortBy: 'run_date',
    page: 1,
    sortDesc: []
  },
  total: 0,
  print: false,
  export: false,
  print_path: '',
  stub: {
    ditch: {},
    farmunit: {},
    diversionPoint: {},
    user: {},
    producer: {}
  },

  bound: {

  },
  filter: {
  },
  include: '',
  headers: {},
  notifications: {}
})

// Mutations ==================

export const mutations = {
  ...globalMutations
}

// Actions ====================

export const actions = {
  ...globalActions,
  async upload_logo ({ commit, state, getters, dispatch, rootState }, payload) {
    const results = await this.$repositories[state.type].create(payload)

    if (results.hasOwnProperty('error')) {
      dispatch('set_snack', results)
      return false
    } else {
      await this.$auth.fetchUser()
    }
  }
}

// Getters ====================

export const getters = {
  ...globalGetters
}
