import {
  resolveRelations,
  hasOwnProp
} from './ApiHelpers'

export default {
  standardizedArray: (state, getters) => (list) => {
    return list ? list.map(item => getters.find(item)).filter(item => item) : []
  },
  getAll (state) {
    return state.all
  },
  standardized (state, getters) {
    const all = Object.values(state.all)
    return all.map(item => getters.find(item))
  },
  getActive: (state, getters, rootState) => (id) => {
    return state.all[id]
  },
  find: (state, getters, rootState) => (item) => {
    let id = item
    if (typeof id === 'object' && id !== null) {
      id = item.id
    }
    return state.all[id]
      ? resolveRelations(state.all[id], state.relations, rootState)
      : false
  },
  lastUpdated (state, getters) {
    let lastUpdated = ''
    const all = Object.values(state.all)
    if (all.length > 0) {
      if (hasOwnProp(all[0], 'last_updated_date')) {
        const dates = all.map(i => i.last_updated_date)
        dates.sort()
        dates.reverse()
        lastUpdated = dates[0]
      }
    }
    return lastUpdated
  },
  quickfilters (state) {
    const quickFilters = resolveSavedFilters(state.type)

    // console.log({ quickFilters })

    return quickFilters
  },
  headers (state, getters, rootState) {
    let headers = []
    if (hasOwnProp(state, 'type')) {
      if (hasOwnProp(rootState.sectionSchemas.all, state.type)) {
        headers = (Object.values(rootState.sectionSchemas.all[state.type].schema.schemas).find(s => s.key === `${state.type}_form`)?.headers || [])
      }
    }
    return headers
  },
  allHeaders (state, getters) {
    const headers = getters.headers

    return headers.filter(function (obj) {
      return obj.used
    })
  },
  visibleHeaders (state, getters) {
    const headers = getters.headers
    return headers.filter(function (obj) {
      return obj.display && obj.used
    })
  },
  publicHeaders (state, getters) {
    const headers = getters.headers
    return headers.filter(function (obj) {
      return obj.display && obj.used && !obj.protected
    })
  },
  tableHeaders (state, getters) {
    const headers = getters.headers
    const filtered = headers.filter(function (obj) {
      return obj.tableDisplay && obj.used
    })

    return filtered.sort(function (a, b) {
      return a.column - b.column
    })
  },
  createHeaders (state, getters) {
    const headers = getters.headers
    const filtered = headers.filter(function (obj) {
      if (hasOwnProp(obj, state.type, 'createDisplay')) {
        return obj.createDisplay && obj.used
      } else {
        return obj.tableDisplay && obj.used
      }
    })

    return filtered.sort(function (a, b) {
      return a.column - b.column
    })
  },

  filterAttributes (state, getters) {
    const relationships = state.relations
      .map(({ name }) => name)

    return getters.headers
      .filter(function (obj) {
        return obj.used && obj.filterDisplay && !relationships.includes(obj.field)
      })
      .sort(function (a, b) {
        return a.column - b.column
      })
  },
  filterRelations (state, getters) {
    return getters.headers.map(({ name }) => name)
  },
  sortData (state) {
    let x

    if (state.descending) {
      x = '-' + state.sortBy
    } else {
      x = state.sortBy
    }
    return x
  },
  showParams (state, getters, rootState) {
    const x = {}

    const waterYear = rootState.system.waterYear
    const om = Object.values(rootState.omsettings.all).find(
      om => parseInt(om.year) === parseInt(waterYear)
    )

    const yearArr = [
      om ? om.water_year_start : `${waterYear}-01-01`,
      om ? om.water_year_end : `${waterYear}-12-31`
    ]

    for (const relation of Object.values(state.relations)) {
      if (
        hasOwnProp(relation, 'yearFields') &&
                relation.yearFields.length > 0
      ) {
        const k = hasOwnProp(relation, 'translate')
          ? relation.translate
          : relation.name
        for (const v of relation.yearFields) {
          x[`filter_exact[${k}.${v}]`] = yearArr
        }
      }
    }

    if (state.include.length > 1) {
      x.include = state.include
    }

    x.waterYear = rootState.system.waterYear

    return x
  },
  params (state, getters, rootState) {
    const x = {}
    const lastUpdated = getters.lastUpdated
    if (lastUpdated) {
      x['filter_scope[lastUpdated]'] = lastUpdated
    }
    // if (state.type === 'usages' || state.type === 'orders') {
    //   x['filter_scope[waterYear]'] = rootState.system.waterYear
    // }
    x.waterYear = rootState.system.waterYear
    if (state.latest_fetch !== undefined) {
      x.latest_fetch = state.latest_fetch
    }
    x.sort = getters.sortData
    // x.page = state.page
    // if (state.itemsPerPage) {
    //   x.limit = state.pagination.itemsPerPage
    // }

    if (state.search) {
      const searchTerm = 'filter_scope[search]'
      x[searchTerm] = state.search
    }

    // used to add in filter relations
    // eslint-disable-next-line
        let relationsToinclude = []

    const filter = state.filter
    for (const prop in filter) {
      if (prop === 'exact') {
        Object.entries(filter[prop]).forEach(([key, value]) => {
          const exact = `filter_exact[${key}]`
          const arr = []
          let formattedValue

          if (typeof value === 'object') {
            value = Object.values(value)
          }

          if (value.isArray) {
            value.forEach((v) => {
              arr.push(encodeURIComponent(v))
            })
            formattedValue = arr.join(',')
          } else {
            formattedValue = value
          }

          // if (typeof value === "boolean") {
          //   formattedValue = value;
          // } else {
          //   value.forEach(v => {
          //     arr.push(encodeURIComponent(v));
          //   });
          //   formattedValue = arr.join(",");
          // }
          x[exact] = formattedValue
        })
      } else if (prop === 'relations') {
        // add each of these relations to the include!
        const without = []
        Object.entries(filter[prop]).forEach(([key, value]) => {
          const arr = []

          const filterLabel = state.relations.find((obj) => {
            return obj.name === key
          })
          relationsToinclude.push(filterLabel.translate)
          const exactRelation = `filter_exact[${filterLabel.translate}.id]`

          for (const [k, val] of Object.entries(value)) {
            const keyOfValue = k

            if (k === 'with_rel') {
              const v = Object.values(val)
              v.forEach((item) => {
                arr.push(encodeURIComponent(item))
              })
              const formattedValue = arr.join(',')
              x[exactRelation] = formattedValue
            } else if (k === 'without_rel') {
              const filterLabel = state.relations.find((obj) => {
                return obj.name === key
              })
              // const scopeName = `filter_scope[${k}]`
              // Object.entries(v).forEach(([k, v]) => {
              //   without.push(encodeURIComponent(v));
              // });

              const formattedScope = filterLabel.translate
              without.push(encodeURIComponent(formattedScope))
              // x[scopeName] = [];
            } else {
              const filterRelation = state.relations.find((obj) => {
                return obj.name === key
              })
              // let scopeName = `filter_scope[${k}]`;
              Object.entries(val).forEach(([k, v]) => {
                if (
                  ['orders', 'usages'].includes(key) &&
                                    keyOfValue === 'attr_between_date'
                ) {
                  const dates = Object.values(v).join(',')
                  x[`filter_scope[${keyOfValue}_${key}]`] = `${k},${dates}`
                } else {
                  const exact = `filter_exact[${filterRelation.translate}.${k}]`
                  const arr = []
                  let formattedValue

                  if (typeof v === 'object') {
                    v = Object.values(v)
                  }

                  if (v.isArray) {
                    v.forEach((val) => {
                      arr.push(encodeURIComponent(val))
                    })
                    formattedValue = arr.join(',')
                  } else {
                    formattedValue = v
                  }

                  x[exact] = formattedValue
                }
              })
            }
          }
        })
        if (without.length) {
          x['filter_scope[without_rel]'] = without.join('|')
        }
      } else if (prop !== '') {
        const scope = `filter_scope[${prop}]`
        const arr = []

        if (typeof filter[prop] === 'object') {
          Object.entries(filter[prop]).forEach(([key, value]) => {
            arr.push(key)

            if (typeof value === 'boolean') {
              arr.push(value)
            } else {
              Object.entries(value).forEach(([k, v]) => {
                arr.push(encodeURIComponent(v))
              })
            }
          })
        } else if (
          typeof filter[prop] === 'boolean' ||
                    typeof filter[prop] === 'number' ||
                    typeof filter[prop] === 'string'
        ) {
          arr.push(filter[prop])
        }

        const formattedValue = arr.join(',')

        x[scope] = formattedValue
      }
    }

    if (Array.isArray(state.include) && state.include.length) {
      x.include = state.include.concat(relationsToinclude).join(',')
    } else if (state.include.length) {
      x.include = state.include
    }

    return x
  },
  SET_DYNAMIC (state, resource) {
    state[resource.key] = resource.value
  }
}
