import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4fa45b08 = () => interopDefault(import('../pages/dock.vue' /* webpackChunkName: "pages/dock" */))
const _b9774720 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _9016e3ee = () => interopDefault(import('../pages/reports/index.vue' /* webpackChunkName: "pages/reports/index" */))
const _44abdb1e = () => interopDefault(import('../pages/ResetPassword.vue' /* webpackChunkName: "pages/ResetPassword" */))
const _3715e574 = () => interopDefault(import('../pages/waterMasterReport.vue' /* webpackChunkName: "pages/waterMasterReport" */))
const _55252907 = () => interopDefault(import('../pages/pg/orders/index.vue' /* webpackChunkName: "pages/pg/orders/index" */))
const _dfb07da2 = () => interopDefault(import('../pages/pg/orders/_id.vue' /* webpackChunkName: "pages/pg/orders/_id" */))
const _bc884c8a = () => interopDefault(import('../pages/pg/_slug.vue' /* webpackChunkName: "pages/pg/_slug" */))
const _09aee7fe = () => interopDefault(import('../pages/pg/_slug/index.vue' /* webpackChunkName: "pages/pg/_slug/index" */))
const _499a5c34 = () => interopDefault(import('../pages/pg/_slug/_id.vue' /* webpackChunkName: "pages/pg/_slug/_id" */))
const _4cc68d4e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/dock",
    component: _4fa45b08,
    name: "dock"
  }, {
    path: "/login",
    component: _b9774720,
    name: "login"
  }, {
    path: "/reports",
    component: _9016e3ee,
    name: "reports"
  }, {
    path: "/ResetPassword",
    component: _44abdb1e,
    name: "ResetPassword"
  }, {
    path: "/waterMasterReport",
    component: _3715e574,
    name: "waterMasterReport"
  }, {
    path: "/pg/orders",
    component: _55252907,
    name: "pg-orders"
  }, {
    path: "/pg/orders/:id",
    component: _dfb07da2,
    name: "pg-orders-id"
  }, {
    path: "/pg/:slug?",
    component: _bc884c8a,
    children: [{
      path: "",
      component: _09aee7fe,
      name: "pg-slug"
    }, {
      path: ":id",
      component: _499a5c34,
      name: "pg-slug-id"
    }]
  }, {
    path: "/",
    component: _4cc68d4e,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
