import globalActions from '../api/GlobalActions.js'
import globalMutations from '../api/GlobalMutations.js'
import globalGetters from '../api/GlobalGetters.js'

export const state = () => ({
  isStale: true,
  latest_fetch: '',
  type: 'syncStats',
  effectedByYear: false,
  yearFields: [],
  relations: [],
  aliasField: 'name',
  modelLabels: {
    single: 'Synchronization Stat',
    plural: 'Synchronization Stats'
  },
  filterOverrides: [],
  all: {},
  reference: {},
  active: false,
  page: 1,
  pageCount: 0,
  pagination: {
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    itemsPerPage: 150,
    sort: true,
    sortBy: 'type',
    page: 1,
    sortDesc: []
  },
  print_path: '',
  search: null,
  total: 0,
  print: false,
  export: false,
  bound: {},
  filter: {},
  include: '',
  headers: {},
  notifications: {}
})

export const mutations = {
  ...globalMutations
}

export const actions = {
  ...globalActions
}

export const getters = {
  ...globalGetters
}
